import React, { ReactNode } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { GatsbyBrowser } from "gatsby";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { customTheme } from "../themes/theme";

posthog.init(process.env.GATSBY_PUBLIC_POSTHOG_KEY as string, {
  api_host: process.env.GATSBY_PUBLIC_POSTHOG_HOST,
  bootstrap: {
    featureFlags: {
      "flag-1": true,
      "variant-flag": "control",
    },
  },
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
});

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}: {
  element: ReactNode;
}) => {
  return (
    <PostHogProvider client={posthog}>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Box>{element}</Box>
      </ThemeProvider>
    </PostHogProvider>
  );
};

export default wrapRootElement;
